<template>
  <div class="order_home">
    <div class="bg_img">
      <img src="@/assets/images/img_logo.png" alt="">
    </div>
    <div class="white_box">
      <!-- 搜索，扫码 -->
      <div class="input_btn mt_a_60">
          <!--<van-search
          v-model="SerachValue"
          placeholder="请输入手机号或扫描MEI码查询"
          shape="round"
        >
          <template #left-icon>
            <div class="img_seach g-flex-jb">
              <img src="../../assets/images/icon_ss.png" alt="">
            </div>
          </template>
         <template #right-icon>
            <div class="img_seach g-flex-jb">
              <img src="../../assets/images/home/icon_sm.png" alt="">
            </div>
          </template>
        </van-search>-->
        <div class="search">
          <img class="icon" src="../../assets/images/icon_ss.png" alt="">
          <input type="text" placeholder="请输入手机号或扫描IMEI码查询" v-model="SerachValue">
          <scan-code :just-icon="true" v-model="SerachValue"></scan-code>
        </div>
      </div>
      <!-- 订单类型 -->
      <div class="input_btn mt_a_40">
        <!--<van-search
          v-model="orderValue"
          placeholder="请选择订单类型"
          @click="orderShow = !orderShow"
          shape="round"
        >
          <template #left-icon>
            <div class="img_seach g-flex-jb">
              <img src="../../assets/images/icon_lx.png" alt="">
            </div>
          </template>
         <template #right-icon>
            <div class="img_seach g-flex-jb">
              <div class="g-flex-ac img_w_H" v-if="orderShow"><img src="../../assets/images/home/icon_sjt_.png" alt="" ></div>
              <div class="g-flex-ac img_w_H" v-else><img src="../../assets/images/home/icon_xjt_.png" alt=""></div>
            </div>
          </template>
        </van-search>-->
        <div class="search"  @click="orderShow = !orderShow">
          <img class="icon" src="../../assets/images/icon_lx.png" alt="">
          <input type="text" readonly="readonly" placeholder="请选择订单类型" v-model="orderValue">
          <img class="icon arrow" v-if="orderShow" src="../../assets/images/home/icon_sjt_.png" alt="">
          <img class="icon arrow" v-else src="../../assets/images/home/icon_xjt_.png" alt="">
        </div>

        <van-popup round v-model="orderShow" position="bottom">
            <van-picker
                    :columns="columns"
                    show-toolbar
                    title="订单类型"
                    @cancel="orderShow = !orderShow"
                    @confirm="onConfirm"
            />
        </van-popup>
      </div>
    </div>
    <div class="btn">
      <van-button color="#577BB5" round size="large" @click='submit'>提交</van-button>
    </div>
  </div>
</template>

<script>
import {Toast} from 'vant'

export default {
  name: "orderhome",
  data(){
    return{
      orderShow:false,
      SerachValue:'',
      orderValue:'',
      columns:[]
    }
  },
  mounted(){
    // console.log(this.$route.meta.isUseCache,'this.$route.meta.isUseCache')
    //   if(!this.$route.meta.isUseCache){//false
    //       this.SerachValue = "";
    //       this.orderValue = "";
    //       this.$route.meta.isUseCache = false;
    //   } else {
    //       this.SerachValue = this.$store.state.SerachValue;
    //       this.orderValue = this.$store.state.orderValue;
    //       this.$route.meta.isUseCache = false;
    //   }
    this.SerachValue = this.$store.state.SerachValue||'';
    this.orderValue = this.$store.state.orderValue||'';

    this.$http.orderType().then(res=>{
      this.columns = res&&res.data||[]
      console.log(res,'orderType')
    }).catch(err=>{
      this.$toast(err&&err.msg||'获取订单类型失败')
    })
  },
  methods:{
    // 搜索
    submit(){
      if(!this.SerachValue){
        Toast('请输入手机号或扫描MEI码查询');
      }
      else if(!this.orderValue){
        Toast('请选择订单类型');
      }
      else{
        let params = {
          keyword:this.SerachValue,
          orderType:this.orderValue
        }
        this.$store.commit('SerachValue',this.SerachValue)
        this.$store.commit('orderValue',this.orderValue)
        this.$http.orderQuery(params).then(res=>{
          if(res.data.length>0){
            sessionStorage.setItem('data',JSON.stringify(res.data))
            this.$router.push({path:"/orderHome/detail"})
          }else{
            Toast('暂无数据');
          }
        })
      }
    },
    // 扫码
    smClick(){
      this.$router.push('/Demos')
    },
    // 类型选择
    onConfirm(val){
      console.log(val)
      this.orderValue = val
      this.orderShow = false
    }
  },
}
</script>

<style lang="less" scoped>
.order_home {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(to bottom, #F9FBFE, #E8F2FF);
  .bg_img {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    img{
      width: 428px;
      height: 428px;
    }
  }

  .white_box {
    position: absolute;
    left: 30px;
    right: 30px;
    bottom: 20%;
    width: calc(100vw - 60px);
    height: 439px;
    background: #FFFFFF;
    border-radius: 30px;

    .mt_a_60 {
      margin: 60px auto;
    }

    .mt_a_40 {
      margin: 40px auto;
    }

    .input_btn {
      width: calc(100vw - 120px);
      height: 80px;
      border-radius: 40px;

      .img_seach {
        .img_w_H {
          img {
            width: 24px;
          }
        }

        img {
          width: 40px;
        }
      }
    }
  }

  .btn {
    position: absolute;
    bottom: 107px;
    left: 75px;
    right: 75px;
    height: 88px;
  }
}
.search{
  width: calc(100vw - 120px);
  height: 80px;
  background: #F8F8F8;
  border-radius: 40px;
  display: flex;
  align-items: center;
  padding: 34px;
  input {
    background: transparent;
    width: 500px;
    padding-left: 28px;

    &::-webkit-input-placeholder {
      font-size: 30px!important;
    }

    &::placeholder {
      font-size: 30px!important;
    }
  }
  .icon{
    width: 42px;
    height: 42px;
    &.arrow{
      width: 30px;
      height: 30px;
    }
  }
  input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    font-size:18px;
  }
}
</style>
